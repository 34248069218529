import { FunctionalComponent, h } from "preact";
import axios from "axios";

import { Link } from "react-router-dom";
import { StyledHeader, HeaderMenu } from "./header.styled";
import Logo from "../icons/logo";
import { BACKEND_HOST, APP_HOST } from "../../utils/constants";
import Account from "../icons/account";
import ProfileDropdown from "../profile-dropdown";
import Dropdown from "../dropdown";
import { useUser } from "../../queries/user";

const Header: FunctionalComponent = () => {
  const handleLogoutClick: () => void = async () => {
    try {
      await axios.get(`${BACKEND_HOST}/user/logout?returnTo=${APP_HOST}`);
    } catch (error) {
      console.log("Failed to perform a logout", error);
    }
    window.location.reload();
  };

  const { data: user } = useUser();
  const isLoggedIn = !!user;

  return (
    <StyledHeader>
      <Link to="/">
        <Logo />
      </Link>

      <HeaderMenu>
        {isLoggedIn && (
          <Dropdown title="My Account" titleIcon={<Account />}>
            <ProfileDropdown logoutClick={handleLogoutClick} />
          </Dropdown>
        )}
      </HeaderMenu>
    </StyledHeader>
  );
};

export default Header;
