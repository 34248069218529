import { cloneElement, FunctionalComponent, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { DropdownParentItem, DropdownMenu } from "./dropdown.styled";
import ArrowDown from "../icons/arrow-down";
import { HeaderMenuItem } from "../header/header.styled";

interface IDropdown {
  title: string | JSX.Element;
  titleIcon?: JSX.Element | null;
  showDropdownIcon?: boolean;
  children: VNode<string>;
}

const Dropdown: FunctionalComponent<IDropdown> = ({
  title,
  titleIcon = null,
  showDropdownIcon = true,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const duration = ".15s";
  const delay = isVisible ? "0s" : duration;

  const open = (): void => {
    setIsVisible(true);
  };

  const close = (): void => {
    setIsVisible(false);
  };

  return (
    <div
      onMouseEnter={open}
      onMouseLeave={close}
      onTouchStart={open}
      tabIndex={0}
    >
      <DropdownParentItem>
        {titleIcon}
        <HeaderMenuItem>{title}</HeaderMenuItem>
        {showDropdownIcon && <ArrowDown />}
      </DropdownParentItem>

      <DropdownMenu isVisible={isVisible} duration={duration} delay={delay}>
        {cloneElement(children, { onClose: close })}
      </DropdownMenu>
    </div>
  );
};

export default Dropdown;
