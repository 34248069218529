import { FunctionalComponent, h } from "preact";
import { ProfileDropdownWrapper, LinkStyles } from "./profile-dropdown.styled";
import Lock from "../icons/lock";
import Logout from "../icons/logout";
import Gear from "../icons/gear";

interface IProfileDropdown {
  logoutClick: () => void;
}

const ProfileDropdown: FunctionalComponent<IProfileDropdown> = ({
  logoutClick,
}) => {
  return (
    <ProfileDropdownWrapper>
      <LinkStyles href="/settings">
        <Gear />
        <span>Settings</span>
      </LinkStyles>
      <LinkStyles onClick={logoutClick} role="button">
        <Logout />
        <span>Logout</span>
      </LinkStyles>
    </ProfileDropdownWrapper>
  );
};

export default ProfileDropdown;
