import { h, FunctionalComponent } from "preact";

const ArrowDown: FunctionalComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0L4 4L0 0H8Z"
        fill="#47505A"
      />
    </svg>
  );
};

export default ArrowDown;
