import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const StyledHeader = styled.header`
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  padding: 0 38px;
  align-items: center;
  background: ${COLORS.background};
  border-bottom: 1px solid #e5e7eb;
  justify-content: space-between;
  z-index: 50;

  a {
    text-decoration: none;
  }
`;

export const StyledNav = styled.nav`
  font-size: 100%;

  a {
    height: 70px;
    padding: 0 15px;
    color: #37008f;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    will-change: background-color;
    background: rgba(255, 255, 255, 0);
    transition: all 250ms ease-in-out;

    &:hover,
    &:active {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderMenuItem = styled.span`
  padding: 0;
  margin: 0 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: ${COLORS.textPrimary};
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.5;
  text-decoration: none;
  position: relative;
`;
