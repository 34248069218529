import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";
import { apiClient } from "../utils/constants";
import { auth0Login } from "../services/auth";
import { IUser } from "../types";

type CustomQueryOptions<T> = Omit<UseQueryOptions<T>, "queryFn" | "queryKey">;

const baseOptions = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useUser = (options?: CustomQueryOptions<IUser | undefined>) => {
  const navigate = useNavigate();

  return useQuery<IUser | undefined>({
    queryKey: ["user"],
    queryFn: async () => {
      try {
        const response = await apiClient.get<IUser>("/user/me");
        return response.data;
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.status === 404) {
            navigate("/user-not-found");
          } else {
            auth0Login();
          }
        }
      }
    },
    ...baseOptions,
    ...options,
  });
};
