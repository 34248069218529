import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const LinkStyles = styled.a`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.textPrimary};
  text-decoration: none;
  cursor: pointer;
  padding: 11px 10px 11px 15px;
  border-radius: 8px;
  width: 100%;
  white-space: nowrap;

  &:hover {
    background-color: #f8f8fa;
    border-radius: 8px;
  }

  & span {
    display: inline-block;
    margin-left: 12px;
  }
`;

export const ProfileDropdownWrapper = styled.div`
  padding: 12px;
  min-width: 150px;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${COLORS.background};
  box-shadow: 20px 0px 80px rgba(16, 5, 48, 0.12);
  position: absolute;
  top: 13px;
  left: -50px;

  &::after {
    content: "";
    position: absolute;
    top: -18px;
    right: 27px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent #fff transparent;
  }

  &::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 100%;
    top: -20px;
    right: 0;
  }
`;
