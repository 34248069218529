import { h, FunctionalComponent } from "preact";

const Account: FunctionalComponent = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9C1 4.58172 4.58172 1 9 1V1C13.4183 1 17 4.58172 17 9V9C17 13.4183 13.4183 17 9 17V17C4.58172 17 1 13.4183 1 9V9Z"
        stroke="#47505A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60001 7.4C6.60001 6.07452 7.67452 5 9.00001 5V5C10.3255 5 11.4 6.07452 11.4 7.4V7.4C11.4 8.72548 10.3255 9.8 9.00001 9.8V9.8C7.67452 9.8 6.60001 8.72548 6.60001 7.4V7.4Z"
        stroke="#47505A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9892 15.2541C12.6213 16.3468 10.887 17 9.00008 17C7.11318 17 5.37886 16.3468 4.01094 15.2541C4.93794 13.4411 6.82407 12.2 9.00008 12.2C11.1761 12.2 13.0622 13.4411 13.9892 15.2541Z"
        stroke="#47505A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Account;
