import { h, FunctionalComponent } from "preact";

const Logout: FunctionalComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M11.7107 5.09991V2.60005C11.7107 2.04777 11.263 1.60007 10.7108 1.60007H2.60005C2.04777 1.60007 1.60007 2.04777 1.60007 2.60005V14.5995C1.60007 15.1517 2.04778 15.5995 2.60005 15.5995H10.7108C11.263 15.5995 11.7107 15.1517 11.7107 14.5995V12.0996"
        stroke="#6179FF"
        strokeWidth="1.74992"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.60014 8.59973H15.5998"
        stroke="#6179FF"
        strokeWidth="1.74992"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0443 7.04422L14.8927 7.89261C15.2832 8.28313 15.2832 8.91628 14.8927 9.3068L14.0443 10.1552"
        stroke="#6179FF"
        strokeWidth="1.74992"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logout;
