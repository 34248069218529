import axios from "axios";
import { BACKEND_HOST, APP_HOST } from "../utils/constants";

export const auth = async (
  username: string,
  password: string,
): Promise<void> => {
  await axios.post(`${BACKEND_HOST}/user/login`, {
    username,
    password,
  });
};

export const auth0Login = (): void => {
  window.open(`${BACKEND_HOST}/connect/auth0?callback=${APP_HOST}/`, "_self");
};

export const auth0Logout = (): void => {
  window.open(`${BACKEND_HOST}/user/logout?returnTo=${APP_HOST}`, "_self");
};
