import styled from "styled-components";

interface IDropdownMenu {
  isVisible?: boolean;
  duration?: string;
  delay?: string;
}

export const DropdownParentItem = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownIcon = styled.span`
  width: 0;
  height: 0;
  margin-left: 10;
  border-style: solid;
  transform: rotate(180deg);
  border-width: 0 4px 4px 4px;
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 10;
  right: 6;
  z-index: 10;
  padding: 10;
`;

export const DropdownMenu = styled.div`
  z-index: 200;
  cursor: auto;
  position: absolute;
  opacity: ${({ isVisible }: IDropdownMenu): 0 | 1 => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }: IDropdownMenu): "visible" | "hidden" =>
    isVisible ? "visible" : "hidden"};
  transition: ${({ duration, delay }: IDropdownMenu): string =>
    `opacity ${duration}, visibility linear 0s ${delay}`};
`;
