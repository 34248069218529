import axios from "axios";

export const BACKEND_HOST =
  process.env.PREACT_APP_BACKEND_HOST || "http://localhost:8100/proxy";

export const APP_HOST = process.env.PREACT_APP_HOST || "http://localhost:8080";

export const FIGMA_CLIENT_ID =
  process.env.PREACT_APP_FIGMA_CLIENT_ID || "ZRaN889V05If9KBFsucA2Z";

export const GTM_ID = process.env.PREACT_APP_GTM_ID || "GTM-T4PRC25";

export const apiClient = axios.create({
  baseURL: BACKEND_HOST,
  withCredentials: true,
});

export const SHOW_SUCCESS_MESSAGE_KEY = "show-success-message";

export const COLORS = {
  primary: "#6179FF",
  primaryFaded: "#f9f5ff",
  secondary: "#FF6F02",
  textPrimary: "#47505A",
  textSecondary: "#242628",
  textTertiary: "#6C737B",
  error: "#FF5A60",
  background: "#FFFFFF",
  backgroundSecondary: "#F5F7FA",
  primaryHover: "#3d5bfe",
  secondaryHover: "#e76300",
  divider: "#E5E7EB",
};
