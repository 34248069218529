import axios from "axios";
import { QueryClientProvider } from "@tanstack/react-query";
import { Fragment, FunctionalComponent, h } from "preact";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useEffect } from "preact/hooks";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import { queryClient } from "../queries/client";
import Callback from "../routes/callback";
import Home from "../routes/home";
import NotFoundPage from "../routes/notfound";
import Settings from "../routes/settings";
import { GTM_ID } from "../utils/constants";
import UserNotFound from "../routes/user-not-found";
import Header from "./header";

axios.defaults.withCredentials = true;

const tagManagerArgs = {
  gtmId: GTM_ID,
};

const App: FunctionalComponent = () => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  });

  return (
    <div id="app">
      <Toaster />
      <script
        src="https://js.chargebee.com/v2/chargebee.js"
        data-cb-site={process.env.PREACT_APP_CHARGEBEE_CB_SITE}
      />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Fragment>
                  <Header />
                  <Home />
                </Fragment>
              }
            />
            <Route
              path="/file-history/*"
              element={
                <Fragment>
                  <Header />
                  <Home />
                </Fragment>
              }
            />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/settings"
              element={
                <Fragment>
                  <Header />
                  <Settings />
                </Fragment>
              }
            />
            <Route path="/user-not-found" element={<UserNotFound />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
};

export default App;
